body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: 'MarkOT';
    src: local('MarkOT'), url('./Mark OT.woff2.ttf') format('truetype');
}

@font-face {
    font-family: 'New Order';
    src: url('./New Order Regular.otf') format('opentype');
}

.sociedades-list {
    height: calc(100vh - 260px);
    overflow-y: auto;
    padding: 10px
}

.sociedad-item {
    width: 100%;
    padding: 5px 5px;
    border-radius: 10px;
}

.sociedad-item:hover {
    background-color: #ccc;
    cursor: pointer;
}

.sala-item {
    min-width: 300px;
    padding: 5px 5px;
    border-radius: 10px;
}

.sala-item:hover {
    background-color: #ccc;
    cursor: pointer;
}

.buttonNuevoDato {
    background-color: #3383EE;
    color: white;
    padding: 5px 20px;
    border-radius: 10px;
    border-color: transparent;
    font-weight: bold;
}

.buttonNuevoDato:hover {
    cursor: pointer;
}

.buttonNuevoDato:active {
    background-color: #2764b4;
}

.buttonSalir {
    margin-top: 10px;
    background-color: #798795;
    color: white;
    padding: 5px 20px;
    border-radius: 10px;
    border-color: transparent;
    font-weight: bold;
}

.buttonSalir:hover {
    cursor: pointer;
}

.buttonSalir:active {
    background-color: #4b535c;
}

.table {
    /* height: calc(100vh - 400px); */
    width: 100%;
}

.table-header div {
    display: inline-block;
    padding: 5px 10px;
    text-align: left;
    min-width: 10%;
}

.table-header div p {
    margin: 0px;
    font-size: 0.7rem;
}

.table-body {
    border: #ccc 1px solid;
    height: calc(100vh - 410px);
    width: 100%;
    overflow-y: auto;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

/* .table-row {
    
} */

.table-row div {
    display: inline-block;
    padding: 5px 10px;
    text-align: left;
    min-width: 10%;
}

.table-row div p {
    margin: 0px;
    font-size: 0.7rem;
}

.fecha {
    width: 9%;
}

.otros {
    width: 5%;
}

.sala {
    width: 5%;
}

.h-totales {
    width: 10%;
}

.h-pastoreo {
    width: 12%;
}

.h-cultivo, .h-recria, .v-masa {
    width: 12.5%;
}

.v-ordenas, .v-preparto {
    width: 13%;
}

.v-secas {
    width: 18%;
}

.v-prenadas {
    width: 14%;
}

.lines {
    border-right: #ccc 1px solid;
    border-bottom: #ccc 1px solid;
}

.lines-bottom {
    border-bottom: #ccc 1px solid;
}

/* .acciones {

} */

.button-icon:hover {
    cursor: pointer;
}

.button-icon:active {
    opacity: 0.6;
}

.popover-container-noareas {
    transition: 500ms;
    width: 500px;
}

.popover-container {
    width: 500px;
    padding: 10px;
    transition: 500ms;
    display: inline-block;
    vertical-align: top;
}

.popover-container-areas {
    transition: 500ms;
    width: 900px;
}

.popover-container-vacas {
    transition: 500ms;
    width: 500px;
}

.popover-container-ver-datos {
    width: 300px;
    /* height: 300px; */
    padding: 0px 16px;
}

/* .fecha, .h-totales, .h-pastoreo, .h-cultivo, .h-recria, .otros, .acciones {
    padding: 5px 10px;
    text-align: left;
    min-width: 10%;
} */

/* .fecha {
    width: 10%;
}

.h-totales {
    width: 15%;
}

.h-pastoreo {
    width: ;
} */