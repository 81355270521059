.welcomMessageBox{
  background-image: url(../../public/landing/prolesur-Banner-kid-cheese.jpeg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: #00539B;
  padding: 20px 5px;
  height: 150px;
  width: 100%;
  border-radius: 10px;
}

.welcomMessageBoxTitle {
  font-size: 18px;
  margin: 0px;
  font-weight: bold;
}

.welcomMessageBoxText {
  font-size: 12px;
  margin: 0px;
  /* font-weight: bold; */
}

@media (max-width: 800px) {
  .welcomMessageBox {
    background: #f0f8ff7a !important;
    /* width: 90%; */
  }
}

.root-welcome {
  background-image: url(../../public/images-landing/vaquita_prolesur_original.jpg);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
}

.root-welcome-2 {
  /* background-image: url(../../public/images-landing/vaquita_prolesur_original.jpg);
  background-repeat: no-repeat;
  background-position: bottom; */
  background-size: cover;
}

.root-other {
  background-color: #F3F3F3;
}

.welcome-text {
  width: 100%;
  font-family: "New Order";
  /* height: 500px; */
}

.welcome-text-title {
  padding: 10px;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
  background-color: #00539B;
  color: white;
  width: 40%;
  margin-left: 25px;
  text-align: center;
}

.welcome-text-title p {
  font-size: 20px;
  font-weight: bold;
  margin: 5px 0px;
}

.welcome-text-content-init {
  height: 0px;
}

.welcome-text-content {
  border-radius: 25px;
  border:#00539B 4px solid;
  background-color: #FFFFFF;
  padding-bottom: 50px;
  transition: 1000ms;
  overflow-y: auto;
}

.heightSonrisaCircular {
  transition: 1000ms;
  height: 1200px;
}

.heightEquipoAgricola {
  transition: 1000ms;
  height: 800px;
}

.heightProgramaSustentabilidad {
  transition: 1000ms;
  height: 1150px;
}

.heightPautasDePago {
  transition: 1000ms;
  height: 600px;
}

.heightActividades {
  transition: 1000ms;
  height: 500px;
}

.container-text {
  padding: 10px;
  text-align: justify;
  color: #00539B;
}

.item-content {
  border-radius: 25px;
  border:#00539B 2px solid;
}

.item-content-up {
  width: 100%;
  /* height: 200px; */
  padding: 10px;
}

.item-content-down {
  width: 100%;
  padding: 10px;
  text-align: center;
}

.item-title {
  font-size: 18px;
  color: #00539B;
}

.item-text-content {
  color: #00539B;
  text-align: left;
}

.item-divider {
  width: 100%;
  border-bottom-color: #00539B;
  border-bottom-width: 2px;
  border-bottom-style: solid;
}

.programa-sustentabilidad-container {
  display: table;
  width: 100%;
  height: 60%;
}

.programa-sustentabilidad-text {
  display: table-cell;
  vertical-align: middle;
  color: #00539B
}

.programa-sustentabilidad-mensaje {
  border-radius: 25px;
  border:#00539B 2px solid;
  background-color: #FFFFFF;
  padding: 20px;
  transition: 1000ms;
  overflow-y: auto;
  color: #00539B;
  font-size: 16px;
}

.programa-sustentabilidad-images {
  width: 100%;
  text-align: left;
}

.pautas-pago-container {
  text-align: center;
  padding: 50px;
}

.pautas-pago-text {
  color:#00539B;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 200px
}

.actividades-container-title {
  width: 100%;
  text-align: left;
}

.actividades-container-title p {
  color: #00539B;
  font-size: 16px;
  font-weight: bold;
}

.actividades-container {
  height: 300px;
}

.button-welcome {
  padding: 20px;
  height: 300px;
  width: 100%;
  margin-bottom: 20px;
}

.button-welcome-container {
  /* background: url(../../public/forraje/zorzal.png)  no-repeat;
  background-size: cover;
  background-position: center; */
  position: relative;
  padding: 20px;
  height: 300px;
  width: 100%;
  border-radius: 10px;
  background-color: white;
  transition: 250ms;
}

.sonriza-circular {
  background-image: url(../../public/landing/soprole-demo-2.jpg);
  background-size: cover;
  background-position: 30px -120px;
}

.equipo-agricola-button {
  background-image: url(../../public/images-landing/vaquita.jpeg);
  background-size: cover;
  background-position: top;
}

.programa-sustentabilidad {
  background-image: url(../../public/landing/Banner-hands-grass-2.jpeg);
  background-size: cover;
  background-position: top;
}

.pautas-pago {
  background-image: url(../../public/landing/soprole-demo1.jpg);
  background-size: cover;
  background-position: top;
}

.actividades {
  background-image: url(../../public/landing/imagen-prolesur.jpeg);
  background-size: cover;
  background-position-y: -100px;
  height: 200px;
}

.button-welcome-container:hover {
  cursor: pointer;
  /* opacity: 0.6; */
  filter: brightness(125%);
  transition: 250ms;
}

.button-welcome-container:active {
  filter: brightness(150%);
  transition: 250ms;
}

.button-welcome-container-selected {
  opacity: 0.6;
  transition: 250ms;
}

.button-welcome-container-title {
  position: absolute;
  top: 0;
  left: 0;
  height: 50px;
  width: 100%;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.444);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.button-welcome-container-title h2 {
  color: #00539B;
  margin-top: 13px;
  margin-bottom: 13px;  
}

.equipo-agricola {
  width: 100%;
  text-align: center;
  color: #00539B;
  padding-top: 15px;
}

.user-list {
  padding: 50px 0px;
}

.user-item {
  color: #00539B;
  margin: 3px 0px 18px 0px;
}

.cargo {
  /* font-weight: bold; */
  font-size: 14px;
}

.text-12 {
  font-size: 12px;
}

.icono-equipo {
  position: absolute;
  right: 20px;
  top: 20px;
  filter: invert(56%) sepia(5%) saturate(61%) hue-rotate(201deg) brightness(101%) contrast(85%);
}

.icono-equipo-activo {
  position: absolute;
  right: 20px;
  top: 20px;
  /* filter: invert(14%) sepia(67%) saturate(4852%) hue-rotate(195deg) brightness(100%) contrast(102%); */
}

.icono-equipo:hover {
  cursor: pointer;
}

.icono-equipo-activo:hover {
  cursor: pointer;
}

.title-equipo-agricola {
  font-weight: bold;
  font-size: 18px;
}

.title-equipo-table {
  font-weight: bold;
  font-size: 16px;
}

.title-equipo-table-row {
  font-weight: bold;
  font-size: 14px;
  text-align: left;
}

.title-equipo-table-content {
  font-weight: bold;
  font-size: 13px;
  color: #2F96D6;
}

.icon-user {
  filter: invert(63%) sepia(72%) saturate(3598%) hue-rotate(176deg) brightness(91%) contrast(83%);
}

.button-container {
  width: 100%;
  position: relative;
}

.button-element {
  position: absolute;
  right: 25px;
  top: 25px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  border-top-left-radius: 25px;
  background-color: #00539B;
  color: white;
  font-size: 13px;
  padding: 10px 35px;
  border-color: transparent;
}

.button-element:hover {
  cursor: pointer;
}

.bottomLine {
  border-bottom: #00539B 3px solid;
}

.leftLine {
  border-left: #00539B 3px solid;
}

.topLine {
  border-top: #00539B 3px solid;
}

.rightLine {
  border-right: #00539B 3px solid;
}